<script lang="ts" setup></script>

<template>
  <main class="page-about container">
    <h1>About</h1>
    <div>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci
      aspernatur est fugiat ipsam labore magnam nisi vel veritatis voluptates!
      Dolor et hic illum nulla odio rem sequi tempore vel voluptate?
    </div>
    <div>
      Cumque deleniti earum et incidunt molestias, natus nemo omnis quos
      sapiente tempora. Consectetur consequatur illo incidunt itaque, maiores
      maxime nostrum praesentium quas quasi quidem quis repudiandae
      voluptatibus! Magni, quod, saepe.
    </div>
    <div>
      Accusantium ad aspernatur consectetur consequatur cumque delectus deleniti
      ea eius enim error eveniet harum iure iusto minima mollitia neque
      officiis, porro praesentium quaerat quas ratione recusandae rem
      reprehenderit sunt voluptates.
    </div>
    <div>
      Ab architecto at dignissimos ea est perferendis perspiciatis sequi?
      Asperiores aspernatur corporis et, ex inventore maiores minus nam omnis
      veritatis voluptatum. Culpa dolorum itaque neque nisi reprehenderit
      suscipit ullam veniam?
    </div>
    <div>
      Adipisci animi aspernatur, cupiditate incidunt laudantium quaerat quidem
      repellat repellendus. Accusantium amet commodi corporis cumque, distinctio
      est excepturi fugiat ipsum maxime molestiae nesciunt officia quisquam quo,
      sit temporibus totam vitae!
    </div>
    <div>
      Architecto culpa dicta, itaque nihil quisquam reiciendis sed sit?
      Accusantium animi assumenda consectetur culpa dolores excepturi, explicabo
      itaque libero modi nihil numquam pariatur praesentium quaerat quibusdam
      voluptates. Asperiores, perspiciatis ut?
    </div>
    <div>
      Ad adipisci at atque autem deserunt dolor dolorum ducimus eaque eius error
      explicabo facere fugit illum impedit inventore minus molestiae neque nobis
      obcaecati officiis porro quibusdam quos ratione ut, veniam!
    </div>
    <div>
      Alias asperiores assumenda at beatae blanditiis commodi cumque dolor
      dolorum fugit illum labore minus modi neque nostrum officiis pariatur quam
      quis quisquam ratione repellat sed sequi soluta, tenetur ullam vitae.
    </div>
    <div>
      Accusamus, assumenda aut cum dicta fugit magnam temporibus vel. Deleniti
      ex id modi provident similique? Atque dolorum, fuga ipsum molestiae,
      nesciunt quae quaerat quis, sequi sunt tempora temporibus velit veritatis.
    </div>
    <div>
      A at eaque incidunt molestiae nisi quam quia quo repellat saepe velit.
      Cupiditate dignissimos distinctio excepturi exercitationem explicabo
      fugiat in laudantium nesciunt nostrum numquam odio, quibusdam recusandae
      repellendus suscipit totam?
    </div>
    <div>
      Aliquid assumenda corporis dicta, doloremque ea eaque eos fugiat in iste
      laborum magnam, minus nisi pariatur perferendis provident quaerat quas
      quidem quo reprehenderit rerum saepe sequi sit. Esse, eveniet,
      repudiandae.
    </div>
    <div>
      Alias at atque deserunt doloribus ex exercitationem illo impedit
      inventore, ipsa iste laborum libero magni modi molestiae molestias nam
      obcaecati omnis perspiciatis quae quia ratione rerum sint ut voluptate
      voluptatibus!
    </div>
    <div>
      Accusamus accusantium adipisci at consequuntur, cumque, cupiditate dicta
      dolor eius eligendi excepturi illum in iste itaque nemo non obcaecati
      pariatur placeat praesentium quam quo quod rem repellendus repudiandae
      saepe voluptates.
    </div>
    <div>
      Adipisci doloremque eos, error, et exercitationem fugiat nobis odit quidem
      repellendus reprehenderit repudiandae saepe! Accusantium aliquam beatae
      dignissimos eius, eum exercitationem expedita mollitia nam, nesciunt
      nostrum perspiciatis, reprehenderit sunt totam.
    </div>
    <div>
      Atque illum libero modi repudiandae voluptatem? Atque dolorem eaque, illo
      in itaque iure magni maiores mollitia nam odio possimus quasi, quod quos
      ratione saepe? Fuga, voluptas voluptates. Accusamus hic, magnam!
    </div>
    <div>
      Cupiditate dolore facere fugit ipsa perferendis praesentium quam,
      quibusdam quisquam temporibus voluptatum. Ipsum, labore quam. Accusantium
      blanditiis, commodi consequatur delectus dolorum ducimus explicabo facere,
      magni nam quisquam rem sint. Eligendi?
    </div>
    <div>
      Accusantium, alias at eligendi enim facere officia! A aliquid blanditiis,
      commodi culpa dolor earum exercitationem facere illo in inventore labore
      laboriosam laudantium modi molestiae nobis quod sit velit voluptates
      voluptatum.
    </div>
    <div>
      Accusamus adipisci inventore nesciunt non numquam officiis omnis.
      Accusantium, at autem delectus doloribus eum, iure libero maxime natus
      nobis obcaecati odio perspiciatis praesentium quas qui repellat sapiente
      sed tempora ut.
    </div>
    <div>
      Adipisci commodi culpa cumque ea earum eius esse est eveniet ex facilis
      fuga, maxime nihil nisi quis suscipit temporibus veniam voluptate
      voluptatum. Eligendi fugit illum itaque molestias omnis quasi sequi.
    </div>
    <div>
      Blanditiis consequuntur dolorum et eveniet modi necessitatibus
      perspiciatis reprehenderit similique unde velit? Adipisci aliquid amet
      dolorum labore maiores nam quod repellendus, sed. Ad culpa dolores
      repellendus rerum tenetur unde voluptatum.
    </div>
    <div>
      Aliquam assumenda atque aut, cumque esse illum incidunt iure laborum minus
      molestias natus neque non obcaecati odit pariatur quam qui quo ratione
      saepe sapiente sit, tenetur unde, velit voluptas voluptatum?
    </div>
    <div>
      A ab at atque ducimus est labore tenetur, vitae? Consequatur excepturi
      itaque minus non, quam vero! Assumenda aut debitis eius ex id minus
      mollitia nihil odio, perspiciatis quas tempore voluptates.
    </div>
    <div>
      Consectetur consequuntur deleniti facilis iusto molestias natus, nobis
      officia praesentium recusandae similique tempora totam unde vitae
      voluptate, voluptates. Molestias nostrum placeat quam sequi, ullam
      veritatis. Aspernatur facere ipsam officia repellendus.
    </div>
    <div>
      Animi consectetur debitis delectus dolorem dolorum eveniet excepturi
      exercitationem facere inventore laborum libero, minima molestiae
      necessitatibus obcaecati provident quam quas quis recusandae rerum sed
      sequi soluta tempora temporibus totam ut.
    </div>
    <div>
      Architecto error mollitia nisi sint. Aperiam blanditiis commodi dolore
      dolorum ducimus ea eius eveniet hic ipsam, laboriosam libero natus, optio
      sed sit vel. Accusamus ad corporis cum facere obcaecati voluptates.
    </div>
    <div>
      Accusantium ad alias, aliquam cumque dolore excepturi fugit illum, ipsum
      iste iusto laborum magnam molestiae nesciunt non numquam odit possimus
      quasi, quibusdam quos rem repellat sapiente temporibus vitae voluptas
      voluptatum?
    </div>
    <div>
      Amet assumenda at aut blanditiis consectetur corporis culpa doloremque
      dolores earum enim est eveniet ipsam iusto minima nam numquam, optio,
      provident quia quisquam quo quos ratione, sed totam ullam vero.
    </div>
    <div>
      Ad asperiores ducimus exercitationem illo necessitatibus, quae quasi
      vitae? Asperiores doloribus earum illum incidunt omnis sapiente. Accusamus
      asperiores deleniti dolorem dolorum, eligendi impedit modi molestiae
      mollitia, officiis possimus repellendus similique.
    </div>
    <div>
      Dolore ducimus eius hic laboriosam nostrum, placeat praesentium recusandae
      similique temporibus! A accusamus, animi, asperiores eaque eum inventore
      nobis obcaecati, praesentium provident quidem quisquam ullam voluptate!
      Itaque laudantium maxime natus!
    </div>
    <div>
      Accusantium asperiores aspernatur assumenda cumque doloribus error et
      excepturi, exercitationem illo ipsa natus nemo nobis non optio perferendis
      possimus praesentium repudiandae suscipit voluptate voluptatem! Doloremque
      ipsam laboriosam minima molestias odit.
    </div>
    <div>
      Animi minus modi quisquam repellat saepe, vel voluptatum. Atque ea est id
      itaque nobis repellat? Distinctio earum error iure magnam porro? Doloribus
      ea exercitationem incidunt inventore, ipsum soluta suscipit unde?
    </div>
    <div>
      Est fugiat ipsam odio placeat quaerat! Harum iure laborum maxime non
      numquam provident quas, quidem quis rem repellat tempore voluptatibus!
      Amet, asperiores dolorem eligendi magnam minima perspiciatis quod sapiente
      ullam?
    </div>
    <div>
      Delectus, eius est fuga iusto magni minima modi molestias neque
      perspiciatis provident quam quidem quis quod, sit suscipit tempora,
      voluptatum. Architecto assumenda aut cumque, earum eos fuga quas suscipit
      veritatis.
    </div>
    <div>
      Amet animi, architecto at blanditiis dolor est exercitationem, in
      inventore itaque, maiores omnis quam recusandae similique? Debitis enim
      quaerat saepe sunt tempora. Ex molestias mollitia reprehenderit sequi
      velit vitae voluptates.
    </div>
    <div>
      Delectus expedita iste itaque libero omnis quas quibusdam! Animi assumenda
      consequatur eius esse, iste molestias nesciunt optio provident, quisquam
      quod ullam vero voluptatem. Delectus non officiis suscipit? Eum, magni,
      neque?
    </div>
    <div>
      Asperiores aspernatur cum expedita itaque laudantium magnam quae ratione
      sed! Commodi error ex excepturi fugit ipsa necessitatibus tempora totam!
      Alias deserunt dignissimos dolore libero magni minus non nostrum, sint
      sunt.
    </div>
    <div>
      Consequuntur cupiditate doloremque explicabo officia. Alias aspernatur, ea
      ipsa ipsum magnam minus nemo neque obcaecati officiis perferendis possimus
      praesentium qui quia quos recusandae sed voluptates! Cumque eum
      exercitationem expedita laudantium.
    </div>
    <div>
      Amet architecto corporis dolore doloremque doloribus eligendi esse
      exercitationem, expedita ipsum laboriosam magnam maxime minima molestias
      nam necessitatibus non, qui quia repellendus repudiandae rerum sequi totam
      vitae voluptatem. Dignissimos, totam.
    </div>
    <div>
      Amet aperiam autem corporis nobis numquam quasi rerum sint. Eos inventore
      maiores odit tempore. Ab aliquam blanditiis consequuntur distinctio enim
      eum eveniet excepturi, iure libero nisi repellat tempora totam, voluptate.
    </div>
    <div>
      Animi commodi distinctio facere in molestiae! Alias ea eaque eligendi esse
      harum illo impedit laudantium maxime molestias necessitatibus nulla
      numquam optio, praesentium quis recusandae reprehenderit sunt ullam veniam
      veritatis voluptates?
    </div>
    <div>
      Cupiditate doloribus ea eaque eveniet fugit illum molestiae perferendis
      quaerat recusandae vel voluptatem, voluptates. Laborum obcaecati ullam ut.
      Aliquam consequatur, ea eaque est ipsum mollitia natus quis quos
      repudiandae tempora.
    </div>
    <div>
      Aperiam assumenda aut, laborum nisi sapiente sed tempora ullam?
      Consequatur distinctio dolorum, hic illum numquam quo! Culpa doloremque
      eaque eligendi fuga, iure, nostrum officiis pariatur rerum sapiente
      tempore velit, veniam?
    </div>
    <div>
      Alias amet, autem doloribus hic inventore mollitia officiis pariatur
      soluta voluptates voluptatum. Aperiam, distinctio eaque eveniet ex, iusto
      minus natus non pariatur perferendis quae quam quas quibusdam repudiandae
      velit, voluptas!
    </div>
    <div>
      Deleniti dolorem eaque fuga nisi provident quaerat sed. Architecto dolores
      eligendi fugiat, iusto maxime nisi sit ut? Asperiores, cupiditate fugiat
      perferendis possimus quis, rem reprehenderit similique, sit tenetur vitae
      voluptate!
    </div>
    <div>
      A, commodi deleniti excepturi, explicabo id itaque iure labore laborum
      maxime neque quisquam sapiente sed sint suscipit tempore. Assumenda et hic
      iste magni molestiae, perferendis quam sapiente tempora voluptas
      voluptate.
    </div>
    <div>
      Aliquid delectus, eaque harum in laborum maxime, minus porro praesentium
      quas quasi quo reprehenderit saepe sequi voluptate voluptatibus? Aliquid
      aut explicabo harum itaque laboriosam natus nisi sapiente veniam vero
      voluptatibus.
    </div>
    <div>
      Ex fuga in pariatur. Alias, asperiores atque aut corporis culpa debitis
      dolor doloremque eligendi eum exercitationem facere facilis itaque iusto
      nesciunt, obcaecati officia praesentium quas quos recusandae unde veniam,
      veritatis?
    </div>
    <div>
      Accusantium alias consequuntur culpa deleniti dignissimos doloremque eum
      facere fugiat illo incidunt iste iure molestias nam nihil nostrum
      obcaecati pariatur quae quam quibusdam, soluta tempore totam ullam,
      veritatis vero voluptas?
    </div>
    <div>
      Debitis ea eaque mollitia nemo obcaecati officiis omnis porro quos tempore
      vel. Adipisci at, aut dicta doloremque enim eos et molestiae praesentium
      quam rem rerum sed sint sunt tempore totam.
    </div>
    <div>
      Alias aliquid, amet aperiam architecto autem corporis cupiditate doloribus
      dolorum ea earum error eveniet explicabo ipsum itaque libero, molestiae
      nesciunt nisi nulla perspiciatis quisquam repellat repellendus
      reprehenderit similique vitae voluptatem?
    </div>
  </main>
</template>
